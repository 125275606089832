import { OrderService } from './../../services/order.service';
import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { RatesService } from '../../services/rates.service';
import { MaterialModule } from '../../modules/material/material.module';
import { CommonModule, DOCUMENT } from '@angular/common';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { PaymentRequest } from '../../models/payment-request';
import { PaymentResponse } from '../../models/payment-response';

@Component({
  selector: 'app-home-page',
  standalone: true,
  imports: [MaterialModule, CommonModule, ReactiveFormsModule],
  templateUrl: './home-page.component.html',
  styleUrl: './home-page.component.scss'
})
export class HomePageComponent {

  // Rates configs
  amountOptions: any[] = [
    20.00, 40.00, 60.00, 80.00, 100.00
  ]
  baseCurrency = "GBP";
  baseCurrencyAmount = 0;
  quoteCurrency='USD';
  quoteCurrencyAmount = this.amountOptions[0];
  rate = 1;

  // User config
  emailControl = new FormControl('', [Validators.required, Validators.email]);

  // UI configs
  payloading = false;
  flagStyle = '';

  constructor(
    private router: Router,
    public ratesService: RatesService,
    public orderService: OrderService,
    @Inject(DOCUMENT) private document: any
    )
  {
    console.log("Document Location", this.document.location);
    this.updateBaseCurrency();
  }

  setLanguageFlag() {
    this.flagStyle = 'flag-style fi fi-' + this.quoteCurrency.slice(0,2).toLocaleLowerCase();
    console.log('flagStyle',this.flagStyle);
  }

  updateBaseCurrency()
  {
    this.ratesService.getFxRate(this.baseCurrency,this.quoteCurrency).subscribe(data => {
      console.log(data.response[0]);
      this.rate = data.response[0].average_bid;
      this.updateBaseAmount();
      this.setLanguageFlag();
    })
  }

  updateBaseAmount()
  {
    if(this.quoteCurrencyAmount == 0){
      this.baseCurrencyAmount = 0;
      return;
    } 
    this.baseCurrencyAmount = Number(((1/this.rate) * this.quoteCurrencyAmount).toFixed(2));
  }

  payOrder()
  {
    console.log("Pay order");
    this.payloading = true;

    console.log("email",this.emailControl);
    const pr: PaymentRequest = {
      host:  this.document.location.origin,
      email: this.emailControl.value!,
      productId: environment.productId,
      productType: this.quoteCurrency,
      productQuantity: this.quoteCurrencyAmount*100,
      currency: this.baseCurrency,
      amount: this.baseCurrencyAmount*100
    }

    console.log("PaymentRequest",pr);

    this.orderService.processPayment(pr).subscribe({
      next: (res: PaymentResponse) => {
        console.log('res',res);
        window.location.href = res.url;
      },
      error: (error) => {
        console.log('error',error);
        this.payloading = false;
      }
    })
  }
}
