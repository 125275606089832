<div class="container">
    <div class="content">
        <div class="title-container">
            <img src="../../../assets/logo01-b.png" alt="Travelex UK" />

            <div class="title-text-container">
                <span class="title-text">Foreign Exchange Services</span>
            </div>
        </div>
        <div class="top-banner">
            <img src="../../../assets/quienes-somos.jpg" alt="Travelex UK" />
            <div class="top-banner-curved-edge"></div>
        </div>

        <div class="order-content">
            <div class="success-container">
                <span class="success-text" *ngIf="!isPaymentReceived && !isTimeOut">In progress ...</span>
                <span class="success-text" *ngIf="isPaymentReceived">Successful payment</span>
                <span class="success-text" *ngIf="isTimeOut;">Payment pending</span>
            </div>

            <ng-container *ngIf="!isPaymentReceived && !isTimeOut">
                <div class="spinner-container">
                    <mat-spinner></mat-spinner>
                </div>
            </ng-container>

            
            <ng-container *ngIf="isPaymentReceived && !isCheckoutExpired">

                <div class="success-text-container">
                    <p class="personal-field-title">Here is your order number and PIN for collection/delivery.</p>
                    <p class="personal-field-title">You've been sent an email containing these details.</p>
                </div>

                <div class="order-container">
                    <div class="order-data">
                        <span class="order-data-title">order number</span>
                        <span class="order-data-number">{{orderNum}}</span>
                    </div>

                    <div class="order-data">
                        <span class="order-data-title">pin</span>
                        <span class="order-data-number">{{pin}}</span>
                    </div>
                </div>

            </ng-container>

            
            <ng-container *ngIf="isPaymentReceived && isCheckoutExpired">

                <div class="success-text-container">
                    <p class="personal-field-title">Your order has already been emailed to you.</p>
                    <p class="personal-field-title">Please check your inbox for your order details.</p>
                </div>

            </ng-container>

            <ng-container *ngIf="isTimeOut">

                <div class="success-text-container">
                    <p class="personal-field-title">Your payment confirmation is taking longer to arrive than expected.</p>
                    <p class="personal-field-title">We'll send you an email with your order details as soon as successful payment is confirmed.</p>
                    <p class="personal-field-title">If you haven't received this email within 30 minutes, first check if the funds were deducted from your account, before submitting a new order.</p>
                </div>
    
                <div class="btn-container">
                    <button class="btn-style" mat-raised-button color="primary" (click)="navigateToHome()">
                        New Order
                    </button>
                </div>

            </ng-container>

            <p class="support-text">For assistance, reach out to us at <a style="text-decoration:none" href = "mailto: {{supportEmail}}">{{supportEmail}}</a></p>
            
        </div>
    </div>
</div>